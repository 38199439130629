/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Seo from "~/components/Seo";
import chapter1Image from "~/images/standards/chapter-1.png";
import chapter2Image from "~/images/standards/chapter-2.png";
import chapter3Image from "~/images/standards/chapter-3.png";
import chapter4Image from "~/images/standards/chapter-4.png";
import chapter5Image from "~/images/standards/chapter-5.png";
import chapter6Image from "~/images/standards/chapter-6.png";
import chapter7Image from "~/images/standards/chapter-7.png";
import chapter8Image from "~/images/standards/chapter-8.png";
import "~/features/marketing/general-page.css";
import "~/features/marketing/map-to-state-standards.css";

function MapToStateStandardsPage() {
  return (
    <div className="Content-outer" id="yui_3_17_2_1_1671159857644_124">
      <main className="Main Main--page">
        <section className="Main-content" data-content-field="main-content">
          <div
            className="sqs-layout sqs-grid-12 columns-12"
            data-type="page"
            id="page-5f31a3dec0835f7cff449498"
          >
            <div className="row sqs-row">
              <div className="col sqs-col-12">
                <div
                  className="sqs-block html-block sqs-block-html"
                  data-block-type="2"
                  id="block-5f31a3dec0835f7cff449499"
                >
                  <div className="sqs-block-content">
                    <h2 style={{ textAlign: "center", whiteSpace: "pre-wrap" }}>
                      How ProblemScape Maps to Common Core{" "}
                    </h2>
                    <p style={{ whiteSpace: "pre-wrap" }}>
                      ProblemScape is a problem-based game for introductory
                      algebra and follows along with the Common Core State
                      Standards for 6th grade algebra. The game has eight
                      chapters, and in each of the chapters students will:&nbsp;
                    </p>
                  </div>
                </div>
                <div
                  className="sqs-block gallery-block sqs-block-gallery"
                  data-block-json='{"aspect-ratio":"square","aspectRatio":null,"auto-crop":true,"blockAnimation":"none","collectionId":"62d1c17dca0045640cda153d","design":"grid","lightbox":false,"padding":20,"show-meta":true,"show-meta-basic":true,"show-meta-only-title":true,"show-meta-only-description":false,"square-thumbs":true,"thumbnails-per-row":4,"vSize":null,"transientGalleryId":"62d1c17dca0045640cda153d","hSize":null,"floatDir":null,"methodOption":"transient","existingGallery":"5e7a910eddb1ef32f872424e","newWindow":false}'
                  data-block-type="8"
                  id="block-yui_3_17_2_1_1657903786136_86611"
                >
                  <div className="sqs-block-content">
                    <div
                      className="
                sqs-gallery-container
                sqs-gallery-block-grid
                sqs-gallery-aspect-ratio-square
                sqs-gallery-thumbnails-per-row-4
                sqs-gallery-block-show-meta
                sqs-gallery-block-meta-only-title
                block-animation-none
                clear"
                      id="yui_3_17_2_1_1671159857644_2326"
                    >
                      <div
                        className="sqs-gallery sqs-gallery-design-grid"
                        id="yui_3_17_2_1_1671159857644_2343"
                      >
                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2381"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="
                    image-slide-anchor
                    content-fill
                  "
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter1Image}
                                  alt="Chapter 1 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter1Image}
                                data-image={chapter1Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.3829791562777039,0.0"
                                data-load="false"
                                data-image-id="62d1c5bd87ae1c575a2ee833"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 1 standards.png"
                                data-image-resolution="300w"
                                src={chapter1Image}
                              />
                            </a>

                            <div className="image-slide-title"></div>
                          </div>
                        </div>
                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2382"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="
                    image-slide-anchor
                    
                    content-fill
                  "
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter2Image}
                                  alt="Chapter 2 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter2Image}
                                data-image={chapter2Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.3829791562777039,0.0"
                                data-load="false"
                                data-image-id="62d1c5bd87ae1c575a2ee833"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 2 standards.png"
                                data-image-resolution="300w"
                                src={chapter2Image}
                              />
                            </a>
                            <div className="image-slide-title"></div>
                          </div>
                        </div>
                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2383"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="
                    image-slide-anchor
                    
                    content-fill
                  "
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter3Image}
                                  alt="Chapter 3 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter3Image}
                                data-image={chapter3Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.5,0.5"
                                data-load="false"
                                data-image-id="62d1c4b850c358755c445a40"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 3 standards.png"
                                data-image-resolution="300w"
                                src={chapter3Image}
                              />
                            </a>
                            <div className="image-slide-title"></div>
                          </div>
                        </div>
                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2384"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="image-slide-anchor content-fill"
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter4Image}
                                  alt="Chapter 4 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter4Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.5,0.5"
                                data-load="false"
                                data-image-id="62d1c444015d7309f1f819fa"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 4 standards.png"
                                data-image-resolution="300w"
                                src={chapter4Image}
                              />
                            </a>

                            <div className="image-slide-title"></div>
                          </div>
                        </div>
                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2385"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="
                    image-slide-anchor
                    
                    content-fill
                  "
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter5Image}
                                  alt="Chapter 5 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter5Image}
                                data-image={chapter5Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.5,0.5"
                                data-load="false"
                                data-image-id="62d1c6376b00bf07c4d865dd"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 5 standards.png"
                                data-image-resolution="300w"
                                src={chapter5Image}
                              />
                            </a>

                            <div className="image-slide-title"></div>
                          </div>
                        </div>

                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2386"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="
                    image-slide-anchor
                    
                    content-fill
                  "
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter6Image}
                                  alt="Chapter 6 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter6Image}
                                data-image={chapter6Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.5,0.5"
                                data-load="false"
                                data-image-id="62d1c6a62e25bc78ea617107"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 6 standards.png"
                                data-image-resolution="300w"
                                src={chapter6Image}
                              />
                            </a>

                            <div className="image-slide-title"></div>
                          </div>
                        </div>
                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2387"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="image-slide-anchor content-fill"
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter7Image}
                                  alt="Chapter 7 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter7Image}
                                data-image={chapter7Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.5,0.5"
                                data-load="false"
                                data-image-id="62d1c6f2497ca550914c73af"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 7 standards.png"
                                data-image-resolution="300w"
                                src={chapter7Image}
                              />
                            </a>
                            <div className="image-slide-title"></div>
                          </div>
                        </div>
                        <div
                          className="slide sqs-gallery-design-grid-slide"
                          data-type="image"
                          data-animation-role="image"
                          id="yui_3_17_2_1_1671159857644_2388"
                        >
                          <div className="margin-wrapper">
                            <a
                              role="presentation"
                              className="image-slide-anchor content-fill"
                              style={{ overflow: "hidden" }}
                            >
                              <noscript>
                                <img
                                  src={chapter8Image}
                                  alt="Chapter 8 standards.png"
                                />
                              </noscript>
                              <img
                                className="thumb-image loaded"
                                data-src={chapter8Image}
                                data-image={chapter8Image}
                                data-image-dimensions="1080x1080"
                                data-image-focal-point="0.5,0.5"
                                data-load="false"
                                data-image-id="62d1c745394e4750981bb850"
                                data-type="image"
                                style={{
                                  opacity: 1,
                                  left: 0,
                                  top: 0,
                                  width: 260,
                                  height: 260,
                                  position: "relative",
                                }}
                                data-parent-ratio="1.0"
                                alt="Chapter 8 standards.png"
                                data-image-resolution="300w"
                                src={chapter8Image}
                              />
                            </a>
                            <div className="image-slide-title"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <style type="text/css" id="design-grid-css">
                #block-yui_3_17_2_1_1657903786136_86611 .sqs-gallery-block-grid .sqs-gallery-design-grid { margin-right: -20px; }
                #block-yui_3_17_2_1_1657903786136_86611 .sqs-gallery-block-grid .sqs-gallery-design-grid-slide .margin-wrapper { margin-right: 20px; margin-bottom: 20px; }
                </style> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export function Head() {
  return <Seo title="Map to state standards" />;
}

export default MapToStateStandardsPage;
